import {StyleSheet, Text, View} from 'react-native';
import React from "react";
import LoginEnode from "./src/lib/login-enode/login-enode";



export default function App() {
    return (
        <View style={styles.container}>
            <Text style={{
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center'
            }}>David Energy</Text>
            <LoginEnode></LoginEnode>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
