import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, StyleSheet, Text, View} from 'react-native';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';

WebBrowser.maybeCompleteAuthSession();
const redirectUri = AuthSession.makeRedirectUri();

const apiUrl = 'http://127.0.0.1:5001';


export default function LoginEnode() {
    console.log(process.env);
    console.log(Object.keys(process.env));
    const [linkUrl, setLinkUrl] = useState(null);
    const [linkToken, setLinkToken] = useState(null);
    const [vehicleInfo, setVehicleInfo] = useState({});
    const [result] = useState({});

    async function createLinkSession() {
        if (apiUrl === undefined || apiUrl === null) {
            console.log('could not find backend api url');
            return;
        }
        const response = await fetch(`${apiUrl}/link?redirect_uri=${redirectUri}`, {
            method: 'GET',
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            setLinkUrl(jsonResponse.linkUrl);
            setLinkToken(jsonResponse.linkToken);
        } else {
            console.log(response.statusText);
            throw new Error(`Request failed! Status code: ${response.status} ${response.statusText}`);
        }
    }

    async function getVehicles() {
        if (apiUrl === undefined || apiUrl === null) {
            console.log('could not find backend api url');
            return;
        }
        const response = await fetch(`${apiUrl}/api/vehicles`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${linkToken}`
            }
        });
        if (response.ok) {
            const jsonResponse = await response.json();
            setVehicleInfo(jsonResponse);
        }
    }

    useEffect(() => {
        (async function setLinkSession() {
            console.log(result);
            if (!linkUrl) {
                createLinkSession();
            }
        })();
    }, [result]);

    useEffect(() => {
        (async function setVehicleData() {
            console.log(vehicleInfo);
            if (Object.keys(vehicleInfo).length === 0) {
                getVehicles();
            }
        })();
    }, [result]);

    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', maxWidth: 100}}>
            <View style={{flexDirection: 'row', backgroundColor: 'white', marginBottom: 20}} key={'login'}>
                {linkUrl && <Button title="Connect with Link" onPress={() => {
                    window.location.assign(linkUrl.toString());
                }
                }/>}
            </View>


            {Object.keys(vehicleInfo).length == 0 ? "" : vehicleInfo['data']['data'].map((item, index) => {
                return (
                    <View style={{flexDirection: 'row', backgroundColor: 'lightgrey'}} key={index}>
                        <View style={{width: 400, backgroundColor: 'white'}}>
                            <Text style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>{item.information.brand}</Text>
                        </View>
                        <View style={{width: 400, backgroundColor: 'lavender'}}>
                            <Text style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>{item.information.model}</Text>
                        </View>
                        <View style={{width: 250}}>
                            <Text style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>Charge: {item.chargeState.batteryLevel}/{item.chargeState.chargeLimit}</Text>
                        </View>
                    </View>
                );
            })
            }

            {/*<Button title="Get Session Info" onPress={() => createLinkSession()}/>*/}
            {/*<Button title="Get Vehicles" onPress={() => getVehicles()}/>*/}

            {/*<View style={styles.container}>*/}
            {/*    <View style={styles.accessTokenBlock}>*/}
            {/*        {Object.keys(vehicleInfo).length == 0 ? "" : <Text>{JSON.stringify(vehicleInfo, null, 2)}</Text>}*/}
            {/*    </View>*/}
            {/*</View>*/}

        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    accessTokenBlock: {
        width: 300,
        // height: 500,
        // overflow: "scroll"
    }
});
